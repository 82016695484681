.chat-widget {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 20;
}

.chat-height-full {
  height: 100%;
}

.chat-button .oj-button-button {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

/*chat*/
.chat-wrapper {
  width: 375px;
  height: 620px;
  max-height: 620px;
}
.chat-wrapper .chat-header {
  height: 56px;
  padding: 0 8px;
  border-radius: 6px 6px 0 0;
  background-color: #312D2A;
}

/*chat conversation*/
.chat-wrapper .chat-conversation {
  background-color: #201E1C;
  overflow-y: auto;
}

.chat-message-bot-icon {
  width: 32px;
  height: 32px;
  background-color: rgb(199, 70, 52);
  border-radius: 5px;
  font-size: 25px;
}
.chat-message-bot-wrapper {
  max-width: 80%;
  min-height: 32px;
  padding: 6px 16px;
  border-radius: 2px 10px 10px 10px;
  background-color: #D4CFCA;
}
.chat-message-user-wrapper {
  max-width: 70%;
  min-height: 32px;
  padding: 6px 16px;
  border-radius: 10px 2px 10px 10px;
  background-color: white;
}

/*chat footer*/
.chat-wrapper .chat-footer {
  background-color: #312D2A;
}
.chat-wrapper .oj-flex-bar-end.chat-footer {
  margin-inline-start: 0;
}

.chat-footer .oj-text-field-container {
  border-color: transparent !important;
  box-shadow: none !important;
}

.chat-message-typing-wrapper {
  height: 32px;
}
.chat-message-typing {
  position: relative;
  width: 40px;
  height: 10px;
}
.chat-message-typing span {
  content: '';
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
  height: 10px;
  width: 10px;
  background: #201E1C;
  left:0;
  top:0;
  border-radius: 50%;
  display: block;
  position: absolute;
}
.chat-message-typing span:nth-child(2) {
  animation-delay: .2s;
  margin-left: 10px * 1.5;
}
.chat-message-typing span:nth-child(3) {
  animation-delay: .4s;
  margin-left: 10px * 3;
}
@keyframes blink {
  0% {
    opacity: .1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
}
